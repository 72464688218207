import React from "react"
import { Popup } from "antd-mobile"
import { IMAGE } from "@/assets"
import "./styles.less"

const IndexComponent = ({
  visible,
  setVisible,
  asset,
  setRechargeVisible,
  setCustomerVisible,
  setInsufficientVisible,
}) => {

  return (
    <Popup
      visible={visible}
      onMaskClick={() => setVisible(false)}
      bodyClassName="m_applyway"
    >
      <img src={IMAGE.close_black} alt="" className="m_applyway_close" onClick={() => setVisible(false)} />
      <p className="m_applyway_title">开卡方式</p>
      <div className="m_applyway_item" style={{ "--bgcolor": "#1437FA" }} onClick={() => {
        setVisible(false)
        if (asset.balance < 16) {
          setInsufficientVisible(true)
        } else {
          setRechargeVisible(true)
        }
      }}>
        <img src={IMAGE.usdt} alt="" />
        <div>
          <p style={{ "--fonts": "18px", "--fontw": 600, "--color": "#ffffff" }}>使用USDT快速开卡 <span>推荐</span></p>
          <p style={{ "--color": "rgba(256, 256, 256, 0.6)" }}>开卡费：5USDT，并充值11USDT</p>
        </div>
        <img src={IMAGE.clickTag} alt="" />
      </div>
      <div className="m_applyway_item" style={{ "--bdcolor": "#E6E6E6" }} onClick={() => {
        setVisible(false)
        setCustomerVisible(true)
      }}>
        <img src={IMAGE.customer2} alt="" />
        <div>
          <p style={{ "--fonts": "18px", "--fontw": 600 }}>人工通道开卡</p>
          <p style={{ "--color": "#808080" }}>适合新手小白，手把手知道开卡</p>
        </div>
        <img src={IMAGE.clickTag} alt="" />
      </div>
    </Popup>
  )
}

export default IndexComponent