import React, { useState, useEffect, useRef } from "react"
import { withRouter, useParams } from "react-router-dom"
import moment from "moment"
import { IMAGE } from "@/assets"
import { Input, Button, Popup, Toast } from 'antd-mobile'
import { post } from "@/service"
import { DelNum } from "@/tool"
import { MTabbar } from "@/component"
import "./styles.less"

const emailReg = /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/

const IndexPage = ({ history }) => {
  const { asset } = useParams()
  const [withdrawNetwork, setWithdrawNetwork] = useState(0)
  const [withdrawMax, setWithdrawMax] = useState(null)
  const [withdrawFee, setWithdrawFee] = useState(null)
  const [withdrawVisible, setWithdrawVisible] = useState(false)
  const [withdrawAddress, setWithdrawAddress] = useState("")
  const [withdrawAmount, setWithdrawAmount] = useState("")
  const [withdrawState, setWithdrawState] = useState(0)
  const [withdrawDetail, setWithdrawDetail] = useState(null)
  const [withdrawCode, setWithdrawCode] = useState("")
  const [payVisible, setPayVisible] = useState(false)
  const [payAddress, setPayAddress] = useState("")
  const [payAmount, setPayAmount] = useState("")
  const [payState, setPayState] = useState(false)
  const [payDetail, setPayDetail] = useState(null)
  const [data, setData] = useState(null)
  const [list, setList] = useState(null)
  const [reload, setReload] = useState(false)
  const timerID = useRef()
  const [timeOut, setTimeOut] = useState(0)

  useEffect(() => {
    async function getData() {
      try {
        Toast.show({
          icon: 'loading',
          content: '加载中...',
          duration: 0
        })
        const resp = await post("/app/account/info")
        localStorage.setItem("email", resp?.email)
      } catch (error) {
        console.log("error:", error)
        Toast.show({
          icon: 'fail',
          content: JSON.stringify(error)
        })
      }
    }
    getData()
  }, [])


  useEffect(() => {
    if (!!asset) {
      async function getData() {
        try {
          Toast.show({
            icon: 'loading',
            content: '加载中...',
            duration: 0
          })
          const resp = await post("/app/account/info/account", {
            "token": asset
          })
          Toast.clear()
          
          setData(resp)
        } catch (error) {
          Toast.show({
            icon: 'fail',
            content: JSON.stringify(error)
          })
        }
      }
      getData()
    }
  // eslint-disable-next-line
  }, [asset, reload])

  useEffect(() => {
    if (!!asset) {
      async function getList() {
        try {
          const resp = await post("/app/account/v4/assetTransactions", {
            "asset": asset,
            "page": 1,
            "pageSize": 100,
            "fromTime": moment().format('x')
          })
          setList(resp.content)
        } catch (error) {
          Toast.show({
            icon: 'fail',
            content: JSON.stringify(error)
          })
        }
      }
      getList()
    }
  }, [asset, reload])

  useEffect(() => {
    async function getWithdrawMaxAndFee() {
      try {
        Toast.show({
          icon: 'loading',
          content: '加载中...',
          duration: 0
        })
        const maxResp = await post("/app/account/withdraw_limitV2", {
          "asset": asset
        })
        setWithdrawMax(maxResp.assetDailyLimit)
        const feeResp = await post("/app/account/withdraw/fee", {
          "type": "WITHDRAW",
          "asset": asset,
          "chainType": data.token.chargeChainTypeObjects[withdrawNetwork].type
        })
        setWithdrawFee(feeResp.fee)
        Toast.clear()
      } catch (error) {
        Toast.show({
          icon: 'fail',
          content: JSON.stringify(error)
        })
      }
    }
    if (withdrawVisible) {
      getWithdrawMaxAndFee()
    }
  // eslint-disable-next-line
  }, [withdrawNetwork, withdrawVisible, asset])

  async function getWithdrawDetail() {
    try {
      Toast.show({
        icon: 'loading',
        content: '加载中...',
        duration: 0
      })
      const resp = await post("/app/account/transfer/detail", {
        "amount": withdrawAmount,
        "asset": asset,
        "toAddress": withdrawAddress,
        "chainType": data.token.chargeChainTypeObjects[withdrawNetwork].type
      })
      Toast.clear()
      setWithdrawDetail(resp)
      setWithdrawState(1)
    } catch (error) {
      Toast.show({
        icon: 'fail',
        content: JSON.stringify(error)
      })
    }
  }

  async function toCaptcha() {
    try {
      Toast.show({
        icon: 'loading',
        content: '加载中...',
        duration: 0
      })
      const resp = await post("app/captcha/needCaptcha", {
        email: localStorage.getItem("email")
      })
      Toast.clear()
      const {need} = resp
      const type = "TENCENT"
      if (need) {
        if (type === "TENCENT") {
          initTencentCaptcha(type).show()
        } else {
          window.grecaptcha.ready(function() {
            window.grecaptcha.execute('6LcSBe8pAAAAADI0vzJo_nAt84cOiNGnrmdAuepT', {action: 'submit'}).then(function(token) {
              sendVerifyCode({
                captchaType: type,
                tencentRandstr: null,
                tencentTicket: null,
                googleResponse: token
              })
            })
          })
        }
      } else {
        sendVerifyCode({
          captchaType: type,
          tencentRandstr: null,
          tencentTicket: null,
          googleResponse: null
        })
      }
    } catch (error) {
      Toast.show({
        icon: 'fail',
        content: JSON.stringify(error)
      })
    }
  }

  function initTencentCaptcha(type) {
    return new window.TencentCaptcha('2038742797', function(res) {
      const {ticket, randstr} = res
      if (!!ticket && ticket.length > 0 && !!randstr && randstr.length > 0) {
        sendVerifyCode({
          captchaType: type,
          tencentRandstr: randstr,
          tencentTicket: ticket,
          googleResponse: null
        }) 
      }
    })
  }

  async function sendVerifyCode(result) {
    try {
      Toast.show({
        icon: 'loading',
        content: '加载中...',
        duration: 0
      })
      result["email"] = localStorage.getItem("email")
      result["tag"] = "WITHDRAW_EMAIL_VERIFY"
      await post("/app/account/sendEmailVerifyCodeV3", result)
      Toast.clear()
      Toast.show({
        icon: 'success',
        content: "成功"
      })
      countDown()
    } catch (error) {
      Toast.show({
        icon: 'fail',
        content: JSON.stringify(error)
      })
    }
  }

  function countDown() {
    if (!!timerID.current) {
      clearInterval(timerID.current)
    }
    let timeOut = 60
    setTimeOut(timeOut)
    timerID.current = setInterval(() => {
      if (timeOut > 0) {
        timeOut = timeOut - 1
        setTimeOut(timeOut)
      } else {
        clearInterval(timerID.current)
      }
    }, 1000)
  }

  async function getWithdrawCode() {
    setWithdrawState(2)
  }

  async function toWithdraw() {
    try {
      Toast.show({
        icon: 'loading',
        content: '加载中...',
        duration: 0
      })
      await post("/app/account/transfer/submitV2", {
        "amount": withdrawDetail.actualCollection,
        "asset": withdrawDetail.asset,
        "toAddress": withdrawDetail.toAddress,
        "chainType": withdrawDetail.chainType,
        "emailVerifyCode": withdrawCode
      })
      Toast.clear()
      Toast.show({
        icon: "success",
        content: "提现成功",
      })
      setWithdrawAddress('')
      setWithdrawAmount('')
      setWithdrawVisible(false)
      setWithdrawState(0)
      setWithdrawCode('')
      setReload(!reload)
    } catch (error) {
      Toast.show({
        icon: 'fail',
        content: JSON.stringify(error)
      })
    }
  }

  async function getPayDetail() {
    try {
      Toast.show({
        icon: 'loading',
        content: '加载中...',
        duration: 0
      })
      const data = emailReg.test(payAddress) ? {
        "amount": payAmount,
        "asset": asset,
        "toEmail": payAddress
      } : {
        "amount": payAmount,
        "asset": asset,
        "toAddress": payAddress
      }
      const resp = await post("/app/account/transfer/detail", data)
      Toast.clear()
      setPayDetail(resp)
      setPayState(true)
    } catch (error) {
      Toast.show({
        icon: 'fail',
        content: JSON.stringify(error)
      })
    }
  }

  async function toPay() {
    try {
      Toast.show({
        icon: 'loading',
        content: '加载中...',
        duration: 0
      })
      const data = !!payDetail.toAddress ? {
        "amount": payDetail.actualCollection,
        "asset": payDetail.asset,
        "toAddress": payDetail.toAddress
      } : {
        "amount": payDetail.actualCollection,
        "asset": payDetail.asset,
        "toEmail": payDetail.toEmail,
      }
      await post("/app/account/transfer/submitV2", data)
      Toast.clear()
      Toast.show({
        icon: "success",
        content: "转账成功",
      })
      setPayAddress('')
      setPayAmount('')
      setPayVisible(false)
      setPayState(false)
      setReload(!reload)
    } catch (error) {
      Toast.show({
        icon: 'fail',
        content: JSON.stringify(error)
      })
    }
  }
  
  return (
    <div className="token-page">
      <div className="content-div">
        {/* <div className="nav-bar" >
          <img src={IMAGE.back_white} alt="" onClick={() => history.goBack()} />
        </div> */}
        {
          !!data ? <>
            <p className="number">{DelNum(data.balance)} <span className="type">{data.asset}</span></p>
            <p className="value">{`≈ $${DelNum(data.balanceUsd)}`}</p>
          </> : <></>
        }
        <div className="menu-list">
          <div key={0} className="menu-item" onClick={() => {
            if (asset === "USDT") {
              history.push('/recharge')
            } else {
              history.push('/swap')
            }
          }}>
            <img src={IMAGE.deposit} alt="" />
            <p>充值</p>              
          </div>
          {
            asset !== "USD" && <div key={1} className="menu-item" onClick={() => setWithdrawVisible(true)}>
              <img src={IMAGE.withdraw} alt="" />
              <p>提现</p>              
            </div>
          }
          <div key={2} className="menu-item" onClick={() => setPayVisible(true)}>
            <img src={IMAGE.pay} alt="" />
            <p>内部转账</p>              
          </div>
          {/* <div key={3} className="menu-item" onClick={() => history.push('/swap')}>
            <img src={IMAGE.swap} alt="" />
            <p>兑换</p>              
          </div> */}
        </div>
      </div>
      {
        !!list && list.length > 0 ? <div className="record-list">
        {
          list.map((item, index) => {
            return <div key={index} className="record-item">
              <img src={item.icon} alt="" />
              <div className="record-desc-div">
                <div className="content">
                  <p className="t1">{ item.tag === 'PAID' ? (item.info || item.title) : item.title }</p>
                  <p className="t2" style={{ marginRight: "20px", width: 'calc(100% - 20px)' }}>
                  {`
                    ${
                      item.tag === 'EXCHANGE_OUT' ? '到' : 
                      item.tag === 'SWAP' ? '已兑换' : 
                      item.tag === 'EXCHANGE_IN' || item.tag === 'EXCHANGE_REWARD' || item.tag === 'EXCHANGE_OUT_REFUND' ? '从' : 
                      item.tag === 'DEPOSITED' ? '从' : 
                      item.tag === 'WITHDREW' || item.tag === 'PAID' ? '到' : ''
                    }:
                    ${
                      item.tag === 'EXCHANGE_OUT_REFUND' ? item.info :
                      item.tag === 'EXCHANGE_IN' || item.tag === 'EXCHANGE_REWARD' || item.tag === 'DEPOSITED' ? item.from :
                      item.to
                    }
                  `}
                  </p>
                </div>
                <div className="content" style={{ alignItems: 'flex-end' }}>
                  <p className="t1">{`${item.tag === 'DEPOSITED' || item.tag === 'EXCHANGE_IN' || item.tag === 'EXCHANGE_REWARD' || item.tag === 'EXCHANGE_OUT_REFUND' ? '+' : '-'}${DelNum(item.amount)} ${item.asset}`}</p>
                  <p className="t2" style={{ whiteSpace: 'nowrap' }}>{ moment(item.time).format("YYYY-MM-DD HH:mm") }</p>
                </div>
              </div>
            </div>
          })
        }
        </div> : <div className="record-list">
          <p className="empty-text">您的交易记录将会显示在这里</p>
        </div>
      }
      <Popup
        visible={withdrawVisible}
        bodyClassName="token-popup"
      >
        <img src={IMAGE.close_black} alt="" className="close" onClick={() => {
          setWithdrawAddress('')
          setWithdrawAmount('')
          setWithdrawVisible(false)
          setWithdrawState(0)
          setWithdrawCode('')
        }} />
        {
          withdrawState === 0 && data ? <>
            <p className="title">USDT提现</p>
            <div className="session-div">
              <p className="name">区块链</p>
              <div className="network-list">
              {
                data.token.chargeChainTypeObjects.map((item, index) => {
                  return <div key={index} className={`network-item ${withdrawNetwork === index ? 'network-item-sel' : ''}`} onClick={() => setWithdrawNetwork(index)}>{ item.name }</div>
                })
              }
              </div>
            </div>
            <div className="session-div">
              <p className="name">提现到</p>
              <div className="input-div">
                <div className="input-container">
                  <Input placeholder="地址" value={withdrawAddress} className="input" onChange={value => setWithdrawAddress(value)} />
                </div>
              </div>
            </div>
            <div className="session-div">
              <p className="name">数量</p>
              <div className="input-div">
                <div className="input-container">
                  <Input placeholder="数量" value={withdrawAmount} className="input" onChange={value => setWithdrawAmount(value)} />
                  {
                    withdrawFee !== null && <p className="all-button" onClick={() => setWithdrawAmount(Math.max(parseFloat(DelNum(parseFloat(data.balance - withdrawFee))), 0))}>全部</p>
                  }
                </div>
                {/* <p className="value">≈ $0.00</p> */}
              </div>
            </div>
            <p className="txt" style={{ marginTop: '24px' }}><span>余额:</span> {`${DelNum(data.balance)} ${asset}`}</p>
            {
              withdrawFee !== null &&  <p className="txt"><span>最小值:</span> {`${DelNum(Math.max(data.token.withdrawChainTypeObjects[withdrawNetwork].minWithdrawAmount, withdrawFee))} ${asset}`}</p>
            }
            {
              withdrawMax !== null && <p className="txt"><span>每日限额:</span> {`${DelNum(withdrawMax)} ${asset}`}</p>
            }
            {
              withdrawFee !== null && <p className="txt"><span>手续费:</span> {`${DelNum(withdrawFee)} ${asset}`}</p>
            }
            <Button className="confirm-button" disabled={!withdrawAddress || !withdrawAmount} onClick={() => getWithdrawDetail()}>继续</Button>
          </> :
          withdrawState === 1 ? <>
            <p className="title">提现确认</p>
            <div className="session-div">
              <div className="type-div">
                <p className="key">实际支付</p>
                <p className="value1">{`${DelNum(withdrawDetail?.actualPayment)} ${asset}`}</p>
              </div>
              {/* <p className="dollar">{`≈ $${DelNum(withdrawDetail?.actualPaymentPrice)}`}</p> */}
            </div>
            <div className="session-div">
              <div className="type-div">
                <p className="key">实际到账</p>
                <p className="value2">{`${DelNum(withdrawDetail?.actualCollection)} ${asset}`}</p>
              </div>
              {/* <p className="dollar">{`≈ $${DelNum(withdrawDetail?.actualCollectionPrice)}`}</p> */}
            </div>
            <div className="session-div">
              <div className="type-div">
                <p className="key">手续费</p>
                <p className="value3">{`${DelNum(withdrawDetail?.fee)} ${asset}`}</p>
              </div>
              {/* <p className="dollar">{`≈ $${DelNum(withdrawDetail?.feePrice)}`}</p> */}
            </div>
            <div className="line"></div>
            <p className="receive-address"><span>到</span>{withdrawDetail?.toAddress}</p>
            <Button className="confirm-button" onClick={() => getWithdrawCode()}>确认</Button>
          </> : <>
            <p className="title">提现验证</p>
            <div className="session-div">
              <p className="name">邮箱验证码</p>
              <div className="input-div">
                <div className="input-container">
                  <Input placeholder="验证码" value={withdrawCode} className="input" onChange={value => setWithdrawCode(value)} />
                  {
                    timeOut === 0 ? <p className="resend-button" onClick={() => toCaptcha()}>发送</p> : <p className="resend-button resend-button-disable">{`重新发送 ${timeOut}s`}</p>
                  }
                </div>
              </div>
              <Button className="confirm-button" disabled={!withdrawCode || withdrawCode.length === 0} onClick={() => toWithdraw()}>确认</Button>
            </div>
          </>
        }
      </Popup>
      <Popup
        visible={payVisible}
        bodyClassName="token-popup"
      >
        <img src={IMAGE.close_black} alt="" className="close" onClick={() => {
          setPayAddress('')
          setPayAmount('')
          setPayVisible(false)
          setPayState(false)
        }} />
        {
          !payState ? <>
            <p className="title">{`${asset}转账`}</p>
            <div className="session-div">
              <p className="name">转账到</p>
              <div className="input-div">
                <div className="input-container">
                  <Input placeholder="地址/邮箱" value={payAddress} className="input" onChange={value => setPayAddress(value)} />
                </div>
              </div>
            </div>
            <div className="session-div">
              <p className="name">数量</p>
              <div className="input-div">
                <div className="input-container">
                  <Input placeholder="数量" value={payAmount} className="input" onChange={value => setPayAmount(value)} />
                  <p className="all-button" onClick={() => setPayAmount(Math.floor(data?.balance * 100) / 100)}>全部</p>
                </div>
                {/* <p className="value">≈ $0.00</p> */}
              </div>
            </div>
            <p className="txt" style={{ marginTop: '24px' }}><span>余额:</span> {`${DelNum(data?.balance)} ${asset}`}</p>
            <Button className="confirm-button" disabled={!payAddress || !payAmount} onClick={() => getPayDetail()}>继续</Button>
          </> : <>
            <p className="title">转账确认</p>
            <div className="session-div">
              <div className="type-div">
                <p className="key">实际支付</p>
                <p className="value1">{`${DelNum(payDetail?.actualPayment)} ${asset}`}</p>
              </div>
              {/* <p className="dollar">{`≈ $${DelNum(payDetail?.actualPaymentPrice)}`}</p> */}
            </div>
            <div className="session-div">
              <div className="type-div">
                <p className="key">实际到账</p>
                <p className="value2">{`${DelNum(payDetail?.actualCollection)} ${asset}`}</p>
              </div>
              {/* <p className="dollar">{`≈ $${DelNum(payDetail?.actualCollectionPrice)}`}</p> */}
            </div>
            <div className="session-div">
              <div className="type-div">
                <p className="key">手续费</p>
                <p className="value3">{`${DelNum(payDetail?.fee)} ${asset}`}</p>
              </div>
              {/* <p className="dollar">{`≈ $${DelNum(payDetail?.feePrice)}`}</p> */}
            </div>
            <div className="line"></div>
            {
              payDetail?.toAddress && <p className="receive-address"><span>到</span>{payDetail?.toAddress}</p>
            }
            {
              payDetail?.toEmail && <p className="receive-address"><span>到</span>{payDetail?.toEmail}</p>
            }
            <Button className="confirm-button" onClick={() => toPay()}>确认</Button>
          </>
        }
      </Popup>
      <MTabbar selIndex={1} />
    </div>
  )
}
export default withRouter(IndexPage)