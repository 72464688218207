import React, { useState, useEffect } from "react"
import { withRouter } from "react-router-dom"
import { IMAGE } from "@/assets"
import { Input, Button, Toast, Modal } from "antd-mobile"
import { MApplyway, MInsufficient, MRecharge } from "@/component"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { post } from "@/service"
import "./styles.less"

const supportWeChat = "LZKJKF1"

const IndexPage = ({ history }) => {
  const [firstName, setFirstName] = useState("")
  const [secondName, setSecondName] = useState("")
  const [cardRule, setCardRule] = useState(null)
  const [assets, setAssets] = useState([])
  const [asset, setAsset] = useState(null)
  const [wayVisible, setWayVisible] = useState(false)
  const [rechargeVisible, setRechargeVisible] = useState(false)
  const [customerVisible, setCustomerVisible] = useState(false)
  const [insufficientVisible, setInsufficientVisible] = useState(false)
  const assetIndex = 0

  useEffect(() => {
    const getCardRules = async () => {
      try {
        Toast.show({
          icon: "loading",
          content: "加载中...",
          duration: 0
        })
        const resp = await post("card/rules")
        if (!resp) {
          Toast.clear()
          return
        }
        setCardRule(resp)
      } catch (error) {
        Toast.show({
          icon: "fail",
          content: error
        })
      }
    }
    getCardRules()
  }, [])

  useEffect(() => {
    async function getAssets() {
      try {
        Toast.show({
          icon: "loading",
          content: "加载中...",
          duration: 0
        })
        const resp = await post("/app/account/info")
        if (!!resp) {
          const accounts = resp.accounts
          const AccountUSD = accounts.find(account => account.asset === "USD")
          AccountUSD.openCardFee = 5
          AccountUSD.minRecharge = 10
          AccountUSD.price = 1
          const AccountUSDT = accounts.find(account => account.asset === "USDT")
          AccountUSDT.openCardFee = Math.ceil(5 / Number(cardRule.usdtPrice) * 100) / 100
          AccountUSDT.minRecharge = Math.ceil(10 / Number(cardRule.usdtPrice) * 100) / 100
          AccountUSDT.price = Number(cardRule.usdtPrice)
          const _assets = [AccountUSDT]
          setAssets(_assets)
        }
        Toast.clear()
      } catch (error) {
        Toast.show({
          icon: 'fail',
          content: JSON.stringify(error)
        })
      }
    }
    if (!cardRule) return
    if (!cardRule.usdtPrice) return
    getAssets()
  }, [cardRule])

  useEffect(() => {
    if (assets.length === 0) return
    if (assetIndex >= assets.length) return
    const _asset = assets[assetIndex]
    setAsset(_asset)
  }, [assets, assetIndex])

  const applyCard = async (amount, asset) => {
    try {
      Toast.show({
        icon: "loading",
        content: "加载中...",
        duration: 0
      })
      await post("/card/apply", {
        "firstName": firstName,
        "lastName": secondName,
        "depositAmount": amount,
        "asset": asset
      })
      Toast.clear()
      Toast.show({
        icon: "success",
        content: "申请开卡成功",
      })
      setTimeout(() => {
        history.goBack()
      }, 500)
    } catch (error) {
      Toast.show({
        icon: "fail",
        content: error
      })
    }
  }

  return (
    <div className="p_cardapply">
      <div className="p_cardapply_navbar">
        <img src={IMAGE.back_black} alt="" onClick={() => history.goBack()} />
      </div>
      <p className="p_cardapply_title">确认您的信息</p>
      <div className="p_cardapply_session">
        <p>卡上的名字</p>
        <Input placeholder="英文/拼音" value={firstName} className="p_cardapply_input" onChange={value => setFirstName(value)} />
      </div>
      <div className="p_cardapply_session">
        <p>卡上的姓氏</p>
        <Input placeholder="英文/拼音" value={secondName} className="p_cardapply_input" onChange={value => setSecondName(value)} />
      </div>
      <Button className="p_cardapply_confirm" disabled={!firstName || !secondName} onClick={() => setWayVisible(true)}>下一步</Button>
      <MApplyway 
        visible={wayVisible}
        setVisible={setWayVisible}
        asset={asset}
        setRechargeVisible={setRechargeVisible}
        setCustomerVisible={setCustomerVisible}
        setInsufficientVisible={setInsufficientVisible}
      />
      {/* <MCustomer 
        visible={customerVisible}
        setVisible={setCustomerVisible}
      /> */}
      <Modal visible={customerVisible} bodyClassName="recharge-modal" content={
        <div className="modal-content-div">
          <p className="title">添加客服Telegram</p>
          <p className="content">{`Telegram：${supportWeChat}`}<br /><span>备注请注明: QPAY代充</span></p>
          <div className="buttons-div">
            <Button className="button cancle" onClick={() => setCustomerVisible(false)}>取消</Button>
            <CopyToClipboard text={supportWeChat} onCopy={() => {
              setCustomerVisible(false)
              Toast.show({
                icon: "success",
                content: "复制成功",
              })
            }}>
              <Button className="button confirm">复制Telegram</Button>
            </CopyToClipboard>
          </div>
        </div>
      } />
      <MInsufficient 
        visible={insufficientVisible}
        setVisible={setInsufficientVisible}
        setCustomerVisible={setCustomerVisible}
        onChange={() => history.push("/recharge")}
      />
      <MRecharge
        visible={rechargeVisible}
        setVisible={setRechargeVisible}
        assets={assets}
        onPay={applyCard}
        fee={asset?.openCardFee || 0}
        isApply={true}
      />
    </div>
  )
}
export default withRouter(IndexPage)